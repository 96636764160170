<template>
  <b-modal size="lg" ref="sendout_template-edit-modal" hide-footer>
    
    <b-form class="mt-8" v-if="local_item">

      <b-row>

        <b-col md="6" sm="12">
            
          <b-form-group
            :label="$t('SENDOUT_TEMPLATE.TEMPLATE_ID')">

            <b-form-select
              v-model="local_item.template_id"
              :options="template_options"
              >
            </b-form-select>

          </b-form-group>

        </b-col>

        <b-col md="6" sm="12">
          
          <b-form-group
            :label="$t('SENDOUT_TEMPLATE.TYPE')">

            <b-form-select
              v-model="local_item.type"
              :options="sendout_template_type_options"
              >
            </b-form-select>

          </b-form-group>

        </b-col>

      </b-row>

      <b-row>

        <b-col md="6" sm="12">
          <b-form-group :label="$t('SENDOUT_TEMPLATE.CREATED_AT')">
            <b-form-input :disabled="true" v-model="local_item.created_at" type="text" :placeholder="$t('SENDOUT_TEMPLATE.CREATED_AT')"></b-form-input>
          </b-form-group>
        </b-col>

      </b-row>

      <p style="margin-top: 42px;"></p>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.OK')"
            :spin="true"
            @clicked="on_submit"
            @close="$refs['sendout_template-edit-modal'].hide()"
            ref="saveButton" />
        </b-col>
      </b-row>
    </b-form>

  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {
  name: 'SendoutTemplateEditorModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  
  components: {
    RightModalSaveAndCloseButtons
  },
  
  computed: {

    template_options() {
      return this.templates.map(item => ({ value: item.template_id, text: item.name }));
    },

    sendout_template_type_options() {
      return [
        { text: 'KVITTO_RECEIVED', value: 'KVITTO_RECEIVED' },
        { text: 'KVITTO_MATCHED', value: 'KVITTO_MATCHED' },
        { text: 'KVITTO_NOT_MATCHED', value: 'KVITTO_NOT_MATCHED' },
        { text: 'ACCOUNT_TRANSACTION_NEED_MATCH', value: 'ACCOUNT_TRANSACTION_NEED_MATCH' },
      ];
    },

    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'customer'])
  },
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }

    this.fetch_template_options();
  },
  data() {
    return {
      local_item: {},

      templates: []
    };
  },
  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.item) {
          this.local_item = { ...this.item };
        }
      }
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    async fetch_template_options() {
      const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

      if (res.status === 200) {
        this.templates = res.data;
      }
    },

    on_submit() {
      if (this.local_item.id) {
        this.put_item();
      }
      else {
        this.post_item();
      }

      this.$refs['saveButton'].stop();
    },

    async put_item() {
      const res = await axios.put(`/template/sendout/${this.local_item.id}`, this.local_item);

      if (res.status === 204) {
        this.$emit('updated', this.local_item);
      }
    },

    async post_item() {
      this.local_item.customer_id = this.customer.id;
      const res = await axios.post(`/template/sendout`, this.local_item);

      if (res.status === 201) {
        this.local_item = res.data;
        this.$emit('created', this.local_item);
      }
    },

    // add any methods we need
    show() {
      this.$refs['sendout_template-edit-modal'].show();
    },

    hide() {
      this.$refs['sendout_template-edit-modal'].hide();
    }
  }
};
</script>
