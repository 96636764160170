<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click.prevent="create_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SENDOUT_TEMPLATE.CREATE') }}</a
        >
      </div>
    </div>


    <b-row class="ml-4 mr-4">
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="advance-file-table"
        :fields="headers"
        :items="items"
        :per-page="per_page"
        :current-page="current_page"
        sort-by="created_at"
        :sort-desc="true"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <template #head(type)="data">
          <tip-label :tip="$t('SENDOUT_TEMPLATE.TYPE')">{{ data.label }}</tip-label>
        </template>

        <template #head(created_at)="data">
          <tip-label :tip="$t('SENDOUT_TEMPLATE.CREATED_AT')">{{ data.label }}</tip-label>
        </template>

        <template #cell(id)="row">
          <div class="justify-content-end d-flex">

            <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_clicked(row.item)" v-b-tooltip="{ title: $t('PAGES.FILES.EDIT_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_clicked(row.item)" v-b-tooltip="{ title: $t('PAGES.FILES.DELETE_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


:deep .td-contain {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 300px;
}


</style>
<script>

import TipLabel from '@/view/components/TipLabel.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'SendoutTemplateTable',
  props: ['items', 'currentCompanyId'],
  emits: ['create_clicked','select_clicked','delete_clicked'],
  mixins: [ toasts ],
  components: { TipLabel },
  computed: {
    total_rows() {
      return this.items.length;
    }
  },
  methods: {
    create_clicked() {
      this.$emit('create_clicked');
    },
    select_clicked(template) {
      this.$emit('select_clicked', template);
    },
    delete_clicked(template) {
      this.$emit('delete_clicked', template);
    },
  },
  data() {
    return {
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'type',
          label: this.$t('SENDOUT_TEMPLATE.TYPE'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain'
        },
        
        {
          key: 'created_at',
          label: this.$t('SENDOUT_TEMPLATE.CREATED_AT'),
          sortable: true,
          thClass: 'w-150px',
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        
        {
          key: 'id',
          label: this.$t('PAGES.FILES.HANDLE'),
          thClass: 'w-150px text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
