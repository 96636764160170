<template>
  <b-modal size="lg" ref="kvitto-select-modal" hide-footer>
    
    <KvittoTable
      :show_connect="true"
      :items="kvittos"
      @connect="connect_clicked"
      @download_kvitto_clicked="download_kvitto"
    />

  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import KvittoEditor from '@/view/pages/ml/kvitto/KvittoEditor.vue';
import KvittoTable from '@/view/pages/ml/kvitto/KvittoTable.vue';

export default {
  name: 'KvittoSelectTableModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['selected', 'updated'],
  components: {
    KvittoEditor,
    KvittoTable
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {
    this.load_kvittos();
  },
  data() {
    return {
      kvittos: [],
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    connect_clicked(kvitto) {
      this.$emit('connect', kvitto);
    },

    download_kvitto(file_id) {},

    async load_kvittos() {
      const res = await axios.get(`/kvitto/company/${this.currentCompanyId}`);

      if (res.status === 200) {
        this.kvittos = res.data;
      }
    },


    kvitto_updated(item) {
      this.$emit('updated', item);
    },

    kvitto_created(item) {
      this.$emit('created', item);
    },

    show() {
      this.$refs['kvitto-select-modal'].show();
    },

    hide() {
      this.$refs['kvitto-select-modal'].hide();
    }
  }
};
</script>
